import { Box } from '@mui/system';
import { useContext, useEffect, useState } from 'react';

import {
    a,
    ColorArray,
    countLen,
    genInitMap,
    historyArray,
    isHint,
    Map2Code,
    OperatorBarStyle,
    PanelRowStyle,
    PanelStyle,
    reGenAfterTravel,
} from '../utils/Util';
import { Cell } from './Cell';
import { DataContext } from './DataProvider';
import { ModalContext } from './modal/Modal';
import { OpButton } from './OpButton';
import { PropButton } from './PropButton';
import { TitleBar } from './TitleBar';

const initState: {
    cover: boolean;
    openHistory: boolean;
    history: number[][];
    TMap: number[][];
    markMap: number[][];
    focus: number[];
    count: number;
    status: number;
} = {
    cover: true,
    openHistory: false,
    history: historyArray,
    TMap: a,
    markMap: a,
    focus: [-1, -1],
    count: 0,
    status: 0  //0：进行中，1：成功，-1：失败
}

const doneText = [
    {
        title: 'success',
        text: 'every toshytoes has been marked!',
    },
    {
        title: 'fail',
        text: 'some toshytoes missed',
    },
    {
        title: 'fail',
        text: 'you bother toshyto ',
    }
]

const Panel = () => {
    const { state } = useContext(DataContext);
    const { dispatch } = useContext(ModalContext);

    const { travel, fixed, hint } = countLen(state.TMap.flat());
    const title = `${fixed}-${travel}-${hint}`

    const [data, setData] = useState(initState)
    console.log('datadatadata', data)
    //   const router = useRouter()

    useEffect(() => {
        let copy = data.markMap.map(_ => [0, 0, 0, 0, 0]);
        setData({ ...initState, TMap: state.TMap, markMap: copy, });
    }, [state.TMap]);

    const handleMark = (idx: number) => {
        if (idx === 99) {
            setData({ ...data, cover: !data.cover })
        }
        if (idx === 0 && data.count === travel + fixed) {
            const res = data.markMap.every((r, ri) => r.every((c, ci) => c !== 1 || data.TMap[ri][ci] < 0))
            console.log('markMapmarkMap', data.markMap)
            const dc = doneText[res ? 0 : 1]
            setData({ ...data, status: res ? 1 : -1, focus: [-1, -1] })
            dispatch({ type: 'open', payload: { name: 'done', title: dc.title, text: dc.text } })
        } else if (idx === 1 && canOpen()) {
            const fv = data.TMap[data.focus[0]][data.focus[1]];

            if (fv < 0) {
                setData({ ...data, status: -1 })
                dispatch({ type: 'open', payload: { name: 'done', title: doneText[2].title, text: doneText[2].text } })
            }

            if (fv % 1 === 0.5) {
                const reGen = reGenAfterTravel(
                    data.TMap,
                    data.focus,
                    25 - travel - fixed - hint
                );

                const historyTmp = data.history.map((r, rhi) =>
                    r.map((c, chi) => {
                        //0:[0,0],1:[1,0],2:[0,1],3:[1,1]
                        //["rgba(222,222,222,0.3)", "#870b34", "#0e6246"];
                        const tmpCom = isHint(data.TMap[rhi][chi]) ? 2 : data.markMap[rhi][chi];
                        if (c === 3 || tmpCom === c) return c;
                        return c + tmpCom;
                    })
                );
                let copy = data.markMap.map(_ => [0, 0, 0, 0, 0]);
                setData({ ...data, history: historyTmp, TMap: reGen, markMap: copy, count: 0 })
            }
        } else if (idx === 2) {
            console.log("history", data.history);
            setData({ ...data, openHistory: !data.openHistory })
        }
    };

    const canOpen = () => {
        if (data.focus[0] < 0 || data.focus[1] < 0) return false;
        return (
            data.markMap[data.focus[0]][data.focus[1]] !== 1 && !isHint(data.TMap[data.focus[0]][data.focus[1]])
        );
    };

    const isTomato = (coor: number[], value: number) => {
        return (
            data.status === -1 && value < 0 && data.focus[0] === coor[0] && data.focus[1] === coor[1]
        );
    };
    console.log('router.params',)
    return (
        <>
            <TitleBar title={title} code={state.title} />

            <Box style={PanelStyle}>
                {data.TMap.map((re, ri) => (
                    <Box key={ri} style={PanelRowStyle}>
                        {re.map((ce, ci) => (
                            <Cell
                                key={`${ri}-${ci}`}
                                value={isHint(ce) ? ce : data.cover ? "" : ce}
                                tomato={isTomato([ri, ci], ce)}
                                cover={data.cover}
                                bg={
                                    isHint(ce)
                                        ? undefined
                                        : isTomato([ri, ci], ce)
                                            ? "rgba(255, 10, 39, 0.3)"
                                            : ColorArray[data.markMap[ri][ci] % 3]
                                }
                                history={data.openHistory ? data.history[ri][ci] : undefined}
                                focus={data.focus[0] === ri && data.focus[1] === ci}
                                onClick={() => {
                                    if (isHint(ce)) {
                                        setData({ ...data, focus: [-1, -1] })
                                        return;
                                    }
                                    let copy = [...data.markMap];
                                    copy[ri][ci] = (data.markMap[ri][ci] + 1) % 3;
                                    setData({ ...data, focus: [ri, ci], markMap: copy, count: copy.flat().filter(v => v === 1).length })
                                }}
                            />
                        ))}
                    </Box>
                ))}
            </Box>
            {data.status === 0 && (
                <PlayingOpBar
                    openHistory={data.openHistory}
                    handleMark={handleMark}
                    canOpen={canOpen()}
                    count={data.count}
                    travel={travel}
                    fixed={fixed}
                />
            )}
            {data.status !== 0 && (
                <DoneOpBar
                    info={`${state.title},${title}`}
                    status={data.status}
                    hint={hint}
                    travel={travel}
                    fixed={fixed}
                />
            )}
            <Prop handleMark={handleMark} />
        </>
    );
};



const DoneOpBar = (props: any) => {
    const { dispatch } = useContext(DataContext)
    const { dispatch: dispatchM } = useContext(ModalContext)

    const retry = () => {
        const tmpMap = genInitMap(props.travel, props.fixed, props.hint)
        dispatch({ type: 'reset', payload: { title: Map2Code(tmpMap), TMap: tmpMap } })
    }
    return (
        <Box style={OperatorBarStyle}>
            <OpButton
                name={'play again'}
                focus
                onClick={retry}
            />
            <OpButton
                name='reset'
                onClick={() => dispatchM({ type: 'open', payload: { name: 'reset' } })}
                focus
            />
        </Box>
    );
}


const PlayingOpBar = (props: any) => {
    const count = props.count;
    const travel = props.travel;
    const fixed = props.fixed;
    return (
        <Box style={OperatorBarStyle}>
            <OpButton
                name={`done ${count}/${travel + fixed}`}
                focus={count === travel + fixed}
                onClick={() => props.handleMark(0)}
            />
            <OpButton
                name={"\xa0\xa0\xa0" + "open" + "\xa0\xa0\xa0"}
                onClick={() => props.handleMark(1)}
                focus={props.canOpen}
            />
            <OpButton
                focus={props.openHistory}
                name='history'
                onClick={() => props.handleMark(2)}
            />

        </Box>
    );
};


const Prop = (props: any) => {
    //help,联系作者
    const { dispatch } = useContext(ModalContext);

    return (
        <Box
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: 'center',
                marginTop: "1vh",
                color: "rgba(0, 212, 255, 0.97)"
            }}
        >
            <PropButton
                clsName='ri-question-fill'
                onClick={() => dispatch({ type: "open", payload: { name: "guide" } })}
            />
            {/* <PropButton onClick={() => props.handleMark(99)} /> */}
        </Box>
    );
};

export { Panel };
