
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { bg } from '../../utils/Util';
import { DialogTitle } from './DialogTitle';
import { TextStyle4Desc } from './GuideDialog';

const ContactDialog = (props:any) => {
  console.log('ShareDialog')
  return <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '3vh', overflow:'auto' }}>
    <DialogTitle title='About Me' />
    <Typography style={{...TextStyle4Desc,marginBottom:'3vh',marginTop:'2vh'}}>
      My dear friends,  if you need help ,or have any suggestions, or share your experience etc. 
      Welcome to  email me, or follow my twitter
    </Typography>
    <SecondaryButton name='twitter: duolingq' href='https://twitter.com/duolingq' />
    <SecondaryButton name='email: duolingquan@gmail.com' href='mailto:duolingquan@gmail.com'/>
  </Box>
}


const SecondaryButton = (props:any) => {
  const matches = useMediaQuery('(min-width:600px)');

  return <Button
    target='_blank'
    href={props.href}
    style={{  textAlign: 'left', 
    backgroundColor: bg, width: '100%', padding: matches?'12px':'3vw', borderRadius: '8px',marginBottom:matches?'12px':'3vh' }}
  >
    <Typography style={{ fontSize: '15px', fontWeight: 'bold', color: '#fff' }}>{props.name}</Typography>
  </Button>
}

export { ContactDialog }
