import { Help } from "@mui/icons-material"
import { Button } from "@mui/material"
import { Box } from "@mui/system"

const PropButton = (props:any) => {
  const height = '55px'
  return <Box
    onClick={props.onClick}
    style={{
      cursor:'pointer',
      border: '2px solid #ede90c', borderRadius: height, height: height, width: height,
      display: 'flex',justifyContent:'center',alignItems:'center',
      backgroundColor: "rgba(247, 243, 2, 0.33)", boxShadow: "1px 2px 6px 0px rgba(217, 255, 0, 0.97)",
    }}
  >
    <Help style={{ fontSize: '28px', color: '#a8a85a' }} />
    {/* <Box className={props.clsName} style={{ fontSize: '28px', color: '#a8a85a' }} /> */}
  </Box>
}

export { PropButton }
