import { CSSProperties } from "react";

/**
 * -1 普通番茄，-2 旅行番茄，+0.5标识覆盖,eg.1.5,负数全部覆盖，扩展状态，eg.0.1，0.2等
 */
const TomatoArray = [
  [-1, -2, -1, -1, -2],
  [-1, -2, 0, -1, 2],
  [-1, -2, 0, 1, 2],
  [-1, -2, 0, 1, -2],
  [-1, -2, 0, 1, 2]
];

/**
 * 挑选旅行番茄坐标，以作为后续变换
 */
const genCoor = (arr: number[][][]) => {
  const a = arr
    .map(e => e.filter(row => row[2] >= 0))
    .filter(e => e.length > 0);
  const travel = arr
    .map(e => e.filter(row => row[2] === -2))
    .filter(e => e.length > 0);
  const d = a.flat();
  return d[Math.round(Math.random() * d.length)]; //长度为0即没有
};

const genField = (array: number[][]) => {
  const arr = TomatoArray.map((re, ridx) =>
    re.map((ce, cidx) => [ridx, cidx, ce])
  );
  const ret = genCoor(arr);
};

/**
 * 生成初始地图
 * @param travel
 * @param fixed
 * @param hint
 * @returns
 */
const genInitMap = (travel: number, fixed: number, hint: number) => {
  //todo:检查范围
  const a = Array(25)
    .fill(0)
    .fill(-2, 0, travel)
    .fill(-1, travel, travel + fixed)
    .fill(0.5, travel + fixed, 25 - hint);
  const b = a.sort(() => Math.random() - 0.5);
  const chunk = chunkArrayInGroups(b, 5);
  return calc(chunk);
};

/**
 * 有旅行番茄的局，回合重新生成地图
 * @param MapArray
 * @param ClickCoor 点击的坐标，排除不放置旅行番茄
 */
const reGenAfterTravel = (
  MapArray: number[][],
  ClickCoor: number[],
  CoverLen: number
) => {
  const arr = MapArray.map((re, ridx) =>
    re.map((ce, cidx) => [ridx, cidx, ce])
  );
  const Travel = arr.map(e => e.filter(row => row[2] === -2)).flat();
  const Hint = arr
    .map(e => e.filter(row => row[2] >= 0))
    .flat()
    .filter(v => !(v[0] === ClickCoor[0] && v[1] === ClickCoor[1]));

  const TmpArr = Travel.concat(Hint).sort(() => Math.random() - 0.5); //没有-1的固定番茄
  const NewTravel = TmpArr.slice(0, Travel.length); //打乱获得新的旅行番茄坐标,todo:增加随机性？是否需要，或许可以作为技巧？
  const NewCover = TmpArr.slice(Travel.length, Travel.length + CoverLen);
  console.log("NewCover", NewCover);

  const CheckTravel = NewTravel.map(v => `${v[0]},${v[1]}`);
  const CheckCover = NewCover.map(v => `${v[0]},${v[1]}`);

  const newArr = MapArray.map((re, ri) => {
    return re.map((ce, ci) => {
      if (CheckTravel.includes(`${ri},${ci}`)) {
        return -2;
      }
      if (CheckCover.includes(`${ri},${ci}`)) {
        return 0.5;
      }
      if (ce === -1)
        //
        return ce;
      return 0;
    });
  });
  return calc(newArr);
};

const calc = (chunk: number[][]) => {
  //计算雷数
  const newArr = chunk.map((re, ridx) =>
    re.map((ce, cidx) => {
      if (ce < 0) return ce;
      var calcSum = ce % 1;
      for (var ri = Math.max(0, ridx - 1); ri <= Math.min(ridx + 1, 4); ri++) {
        for (
          var ci = Math.max(0, cidx - 1);
          ci <= Math.min(cidx + 1, 4);
          ci++
        ) {
          if (chunk[ri][ci] < 0) calcSum += 1;
        }
      }
      return calcSum;
    })
  );
  return newArr;
};

const chunkArrayInGroups = (arr: number[], size: number) => {
  var myArray: number[][] = [];
  for (var i = 0; i < arr.length; i += size) {
    myArray.push(arr.slice(i, i + size));
  }
  return myArray;
};
const a = chunkArrayInGroups(Array(25).fill(0), 5);
const historyArray = chunkArrayInGroups(Array(25).fill(0), 5);
const bg = "rgba(222,222,222,0.06)";
const Map2Code = (IMap: number[][]) => {
  const Four = IMap.flat()
    .map(v => {
      if (v <= 0) return 0 - v;
      if (v % 1 === 0) return 0;
      return 3; //0.5
    })
    .join("");
  console.log("IMap: ", IMap);
  const Ten = parseInt(Four, 4);
  return Ten.toString(32);
}

const Code2Map = (Code: string) => {
  const Ten = parseInt(Code, 32);
  let Four=(Ten).toString(4)
  if(Four.length<25){
    Four=Array(25-Four.length).fill('0').join('')+Four
  }
  const Arr=Four.split('').map(v=>{
    if(v==='0') return 0
    if(v==='3') return 0.5
    return 0-parseInt(v)
  })
  const ret=calc(chunkArrayInGroups(Arr,5))
  console.log('FourFour',Four)
  console.log('ArrayArray',Arr)

  return ret

};

const countLen=(arr:number[])=>{
  const travel=arr.filter(v=>v===-2).length
  const fixed=arr.filter(v=>v===-1).length
  const hint=25 - arr.filter(v=>v%1===0.5).length - travel -fixed
  return {travel,fixed,hint}
}

const ColorArray = ["rgba(222,222,222,0.3)", "#870b34", "#0e6246"];
const HistoryColorArray = ["rgba(222,222,222,0.3)", "rgb(255, 21, 0)", "rgb(85, 234, 16)"];

// const putRecord=(data:string[])=>{
//   Taro.setStorage({
//     key:'record',
//     data:data,
//     success(_) {
//         Taro.showToast({
//           title: '添加成功',
//           icon: 'success',
//           duration: 2000
//         })
//     },
//     fail(_) {
//       Taro.showToast({
//         title: '添加失败',
//         icon: 'error',
//         duration: 2000
//       })
//     },
//   })
// }

// const addRecord=(data:string)=>{
//   Taro.getStorage({
//     key: 'record',
//     success: function (res) {
//       console.log('res.data',res.data)
//       const value:string[]=res.data
//       if(value.includes(data)){
//         Taro.showToast({
//           title: '已添加',
//           icon: 'none',
//           duration: 2000
//         })
//       }else{
//         const tmp=data.length>=1000?data.slice(0,1000):data
//         putRecord([tmp,...value])
//       }
//       // dispatch({ type: 'open', payload: { name: 'record', args: w }})
//     },
//     fail:res=>{
//       console.log('failed',res)
//       putRecord([data])
//     }
//   })
// }

const isHint = (ce: number) => ce >= 0 && ce % 1 === 0;
const OperatorBarStyle: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  margin: "22px 0px 22px 0px"
};
const PanelStyle: CSSProperties = {
  backgroundColor: bg,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "8px",
  marginBottom:'1vh',
  padding: "12px"
};
const PanelRowStyle: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly"
};
export {
  Code2Map,
  HistoryColorArray,
  PanelRowStyle,
  Map2Code,
  OperatorBarStyle,
  PanelStyle,
  ColorArray,
  isHint,
  bg,
  countLen,
  a,
  historyArray,
  chunkArrayInGroups,
  calc,
  reGenAfterTravel,
  TomatoArray,
  genCoor,
  genField,
  genInitMap,
  // addRecord,putRecord
};
