import { Box, Button, FormControl, Input, TextField, useMediaQuery } from '@mui/material';
import { CSSProperties, useContext, useRef, useState } from 'react';

import { Code2Map } from '../../utils/Util';
import { DataContext } from '../DataProvider';
import { PropButton } from '../PropButton';
import { ModalContext } from './Modal';

const Legal = '1234567890abcdefghijklmnopqrstuv'.split('')

const SearchDialog = (props: any) => {
  const matches = useMediaQuery('(min-width:600px)');

  const size = matches ? '50px' : '12vw'
  const width = matches ? '15vw' : '50vw'
  const { state, dispatch } = useContext(DataContext)
  const { dispatch: dispatch1 } = useContext(ModalContext)
  const [err, setErr] = useState(false)
  const inputRef = useRef<any>();

  const ButtonStyle: CSSProperties = {
    width: width, height: size, fontSize: '16px', fontWeight: 'bold', color: '#eee', textAlign: 'center', lineHeight: size,
    borderRadius: '8px',
    backgroundColor: "rgba(236, 243, 237, 0.31)", border: "3px solid #eee",
  }
  const formSubmit = (e: any) => {
    e.preventDefault()
    console.log('e.target', inputRef.current!.value)
    const tmp: string = inputRef.current!.value
    if (tmp.length===0||!tmp.toLowerCase().split('').every(v => Legal.includes(v))) {
      setErr(true)
      return
    }

    const arr = Code2Map(tmp)
    console.log('arrarr', arr)

    if (arr.flat().length !== 25) {
      setErr(true)
      return
    }

    if(arr.flat().length===25 ){
      dispatch({type:'reset',payload:{title:tmp,TMap:arr}})
      dispatch1({type:'close'})
    }

  }
  // const onSubmit = (data) => alert(JSON.stringify(data, null, 4));

  return <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '3vh' }}>
    <Box style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '3vh' }}>{`current:  ${state.title}`}</Box>
    <form onSubmit={formSubmit}>
      <FormControl >
        <Input name='coor' id='coor' type='text' placeholder='enter number of the position'
          inputRef={inputRef}
          style={{ border: '1px solid #ddd', borderRadius: '8px', color: 'white', width: width, padding: '12px', marginBottom: '1vh' }}
        />
        {err && <Box style={{ fontSize: '12px', color: 'red' }}>not found,please check input</Box>
        }
        <Button style={ButtonStyle} type='submit' >Search Field</Button>
      </FormControl>
    </form>
  </Box>
}

export { SearchDialog }
