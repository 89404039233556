
import { Box, Typography } from '@mui/material';
import { TextStyle4Desc } from './GuideDialog';

const DoneDialog=(props:any)=>{
  console.log('ShareDialog')
  return <Box style={{display:'flex',flexDirection:'column',alignItems:'center',padding:'3vh'}}>
  <Box style={{fontWeight:'bold',fontSize:'18px',marginBottom:'3vh'}}>{props.title}</Box>
  <Typography style={TextStyle4Desc}>
      {props.text}
    </Typography>
</Box>
}

export {DoneDialog}
