import { Box, Typography, useMediaQuery } from '@mui/material';
import { CSSProperties, useContext } from 'react';

import { ColorArray } from '../../utils/Util';
import { Cell } from '../Cell';
import { OpButton } from '../OpButton';
import { OptionButton } from '../OptionButton';
import { ModalContext } from './Modal';

const TextStyle4Desc: CSSProperties = { fontSize: '12px', color: '#ddd', lineHeight: '1.8em' }
const GuideDialog = (props:any) => {
  const {dispatch}=useContext(ModalContext)
  return <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '3vh', }}>
    <Box style={{ fontWeight: 'bold', fontSize: '18px',marginBottom:'3vh' }}>how to play</Box>
    <Box style={{ height: '38vh',overflow: 'auto',marginBottom:'3vh' , display: 'flex', flexDirection: 'column',}}>
      <Typography style={{...TextStyle4Desc,marginBottom:'1vh'}}>
        {"\xa0\xa0\xa0" + "dear friend, toshyto is a kind of otaku tomato, can you mark them out according to the clues,   " + "\n\xa0\xa0\xa0" +
          "  most of them like staying at home, while some like traveling, these travel toshyto may change position after each round." + "\n\xa0\xa0\xa0" +
          "  you could open the white cover to get clue, just make sure the cover you open doesn't exist toshyto. remember: mark them out but don't bother them, respect!"}
      </Typography>
      <TagSection />
      <DoneSection />
      <ClueSection />
    </Box>

    <OptionButton name='about me' onClick={() => dispatch({ type: "open", payload: { name: "contact" } })} />
  </Box>
}

const TextStyle4Section: CSSProperties = { fontSize: '16px', color: '#fff', lineHeight: '1.8em', fontWeight: 'bold', margin: '3vh 0vh 2vh 0vh' }
const TextStyle4TagSection: CSSProperties = { fontSize: '12px', color: '#fff', fontWeight: 'bold', }
const ColumnStyle: CSSProperties = { minHeight:'12vh',display: 'flex', flexDirection: 'column', alignItems: 'center' }
const SectionStyle: CSSProperties = { display: 'flex',flexDirection: 'column', marginBottom: '2vh', alignItems:'start' }

const ClueSection = (props:any) => {
  return <Box style={{...SectionStyle,minHeight:'18vh',marginTop:'3vh'}}>
    <Typography style={TextStyle4Section}>Clue Demo</Typography>
    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',minHeight:'18vh',marginBottom: '0.3vh', }}>
      <RowClue />
      <RowClue num={5} />
      <RowClue />
    </Box>
    <Typography style={TextStyle4TagSection}>
      number 5 means there're 5 toshytoes around it in grid
      </Typography>
  </Box>
}

const RowClue = (props:any) => {
  const matches = useMediaQuery('(min-width:600px)');
  const fontSize = '16px'
  const size=matches?'33px':'6vw'

  return <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center',  }}>
    <Cell size={size} bg={ColorArray[0]} fontSize={fontSize} />
    {props.num
      ? <Cell size={size} value={props.num} fontSize={fontSize} />
      : <Cell size={size} bg={ColorArray[0]} fontSize={fontSize} />}
    <Cell size={size} bg={ColorArray[0]} />
  </Box>
}

const DoneSection = (props:any) => {
  const matches = useMediaQuery('(min-width:600px)');

  return <Box style={{...SectionStyle, minHeight:'22vh'}}>
    <Typography style={TextStyle4Section}>Finish Demo</Typography>
    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly',gap:'3vw' }}>
      <Box style={ColumnStyle}>
        <OpButton name='done 6/8' size='5vh' fontSize='12px' padding='1px 3px 1px 3px' width='88px' />
        <Typography style={{...TextStyle4TagSection,marginTop:'0.6vh'}}>
          incomplete,6 out of 8 toshyto has been marked.
        </Typography>
      </Box>
      <Box style={ColumnStyle}>
        <OpButton name='done 8/8' size='5vh' fontSize='12px' padding='1px 3px 1px 3px' focus width='88px' />
        <Typography style={{...TextStyle4TagSection,marginTop:'0.6vh'}}>
          all toshyto has been marked, click button to finish
        </Typography>
      </Box>
    </Box>
  </Box>
}

const TagSection = (props:any) => {
  const matches = useMediaQuery('(min-width:600px)');
  const size=matches?'33px':'6vw'

  return <Box style={{...SectionStyle, minHeight:'16vh'}}>
    <Typography style={TextStyle4Section}>Mark toshyto</Typography>
    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',alignItems:'start',width:'100%'}}>
      <Cell size={size} bg={ColorArray[0]} />

      <Typography style={{ fontSize: '12px', color: '#fff', fontWeight: 'bolder' }}>{'click' + '\n->'}</Typography>

      <Box style={ColumnStyle}>
        <Cell size={size} bg={ColorArray[1]} />
        <Typography style={TextStyle4TagSection}>exist one</Typography>
      </Box>
      <Typography style={{ fontSize: '12px', color: '#fff', fontWeight: 'bolder' }}>{'click' + '\n->'}</Typography>

      <Box style={ColumnStyle}>
        <Cell size={size} bg={ColorArray[2]} />
        <Typography style={TextStyle4TagSection}>not exist</Typography>
      </Box>

    </Box>

  </Box>
}

export { GuideDialog, TextStyle4Desc }
