import { ColorArray, HistoryColorArray } from '../utils/Util';
import Tomato from '../assets/images/tomato.png'
import { Box, useMediaQuery } from '@mui/material';

const getHistoryArray = (bg:any, history:any) => {
  //0:[0,0],1:[1,0],2:[0,1],3:[1,1]
  if (bg === undefined || history === 0) {
    return []
  }

  if (history < 3) return [HistoryColorArray[history]]
  return HistoryColorArray.slice(1)
}


const Cell = (props: any) => {
  const matches = useMediaQuery('(min-width:600px)');
  const ImgSize=matches?'33px':'8vw'

  const size = props.size ?? (matches?13.6*0.38:13.6)+'vw' //'13.6vw'
  if (props.history > 0) {
    console.log('props', props)
  }
  return <Box
    style={{
      margin: '5px',
      cursor:'pointer',
      boxSizing: 'border-box',
      boxShadow: props.focus ? '0 0 0 3px #fff' : undefined,
      borderRadius: '6px',
      backgroundColor: props.bg,
      color: props.fontColor ?? 'white',
      width: size,
      height: size, textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '3px',
      alignItems: 'center',
      position: 'relative'
    }}
    onClick={props.onClick}
  >
    {props.tomato
      ? <img alt='tomato' src={Tomato} style={{ width: ImgSize, height: ImgSize }} />
      : <Box style={{ fontSize: props.fontSize ?? '25px', fontWeight: 'bold', lineHeight: size }}>
        {props.value ?? ""}
      </Box>
    }
    {props.history !== undefined &&
      <Box style={{ display: 'flex', flexDirection: 'row', position:'absolute',bottom:'3px' }}>
        {getHistoryArray(props.bg, props.history)
          .map((e: any) => <HistoryCell key={e} color={e} />)
        }
      </Box>
    }
  </Box>
}

const HistoryCell = (props: any) => {
  return <Box
    style={{
      boxShadow: "0px 1px 2px 0px #000000",margin:"0px 2px 0px 2px",
      width: '8px', height: '8px', borderRadius: "8px", backgroundColor: props.color
    }}
  />
}

export { Cell }
