import { Box, useMediaQuery } from "@mui/material"

const OpButton=(props:any)=>{
  // const padding=props.padding??'8px 16px 8px 16px'
  const matches = useMediaQuery('(min-width:600px)');
  const fontSize=props.fontSize??'15px'
  const size=props.size??(matches?'38px':"6.6vh")
  

  console.log('props',props)
  return <Box
    onClick={props.onClick}
    style={{backgroundColor: props.focus?"rgba(253, 8, 253, 0.66)":"rgba(253, 8, 253, 0.23)",width:props.width,
    height: size,
    cursor:'pointer',
    padding:'0px 8px 0px 8px',
    border:props.focus?"2px solid #fcdefd":'2px solid transparent',boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.79)",borderRadius:'6px',
    lineHeight:size,
    color:props.focus?'#eee':'rgba(255, 179, 179, 0.8)',fontWeight:500,fontSize:fontSize,textAlign:"center",}}
  >
  {props.name}
</Box>
}

export {OpButton}
