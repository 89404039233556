import './App.css';

import React from 'react';

import Index from './pages';
import { useMediaQuery } from '@mui/material';

function App() {
  return <Content />
}

const Content = () => {
  const matches = useMediaQuery('(min-width:600px)');
  // const { state } = useContext(PassGridContext)
  return <>
    <div style={{ width: '100vw',  background: "linear-gradient(180deg, #3d030d 0%, #451b80 100%)", }} >
      <Index />
    </div>
  </>
}


export default App;
