import { createContext, useMemo, useReducer, useState } from 'react';

import { Code2Map, genInitMap, Map2Code } from '../utils/Util';


type DataType = { title: string; TMap: number[][] }

const initialState: DataType = {
  title: "",
  TMap:[]
};

const reducer = (state: DataType, action: any) => {
  console.log('action', action)
  switch (action.type) {
    case 'reset':
      return { ...action.payload }
    default:
      return { ...state }
  }
}

const DataContext = createContext({ state: initialState, dispatch: (e: any) => e })

const DataProvider = (props:any) => {
  // const router = useRouter()
  // const id=router.params['id']
  const id=undefined

  const TMap=id===undefined?genInitMap(6, 6, 8):Code2Map(id!)
  const title=id===undefined?Map2Code(TMap):id
  console.log('TMapTMap',TMap)
  const [state, dispatch] = useReducer(reducer, {title:title,TMap:TMap})
  return <DataContext.Provider value={{ state: state, dispatch: dispatch }}>
    {props.children}
  </DataContext.Provider>

}


export { DataProvider, DataContext };
