import { useContext } from "react";
import { ModalContext } from "./modal/Modal";

import { bg } from "../utils/Util";
import { Box, IconButton } from "@mui/material";
import { Twitter } from "@mui/icons-material";

const TitleBar = (props:any) => {
  console.log("TitleBarTitleBar");
  const { dispatch } = useContext(ModalContext);
  const padding = "12px 6px 12px 6px";
  return (
    <Box
      style={{
        marginBottom: "2vh",
        borderRadius: "8px",
        alignItems: "center",
        backgroundColor: bg,
        fontWeight: 600,
        color: "#aaa",
        textAlign: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }}
    >
      <Box
        style={{ fontSize: "16px", padding: "12px",cursor:'pointer', }}
        onClick={() => {
          dispatch({
            type: "open",
            payload: { name: "reset", args: props.title }
          });
        }}
      >
        {props.title}
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <Box
          style={{
            color: "#888888",
            fontSize: "13px",
            marginLeft: '3px',
            cursor:'pointer',
            padding: padding,
            fontWeight: "bold"
          }}
          onClick={() =>
            dispatch({ type: "open", payload: { name: "search" } })
          }
        >
          {props.code}
        </Box>
        <IconButton 
          href='https://twitter.com/duolingq'
          target='_blank'
          size="small" style={{margin:'0px 3px 0px 3px'}}>
          <Twitter style={{color:'#aaa',}}/>
        </IconButton>
        {/* <Button
          className='ri-share-forward-fill'
          style={{ marginLeft: '3px', fontSize: "16px",backgroundColor: 'red', textAlign:'center',height: '3vh', width: '2vh' }}
          onClick={() => addRecord('asfewer,6-8-12')}
        >
          <Box
            className='remixicon ri-check-double-line'
            style={{ fontSize: "16px",color:'#ccc' }}
            onClick={record}
          />
        </Button> */}
      </Box >
    </Box >
  );
};

export { TitleBar };
