import { Box, useMediaQuery } from '@mui/material';
import { createContext, useMemo, useReducer, useState } from 'react';
import { OptionButton } from '../OptionButton';
import { ContactDialog } from './ContactDialog';
import { DoneDialog } from './DoneDialog';
import { GuideDialog } from './GuideDialog';
// import { OptionButton } from '../OptionButton';
import './Modal.css';
import { ResetDialog } from './ResetDialog';
import { SearchDialog } from './SearchDialog';

const Modal = (props: any) => {
  console.log('propsprops', props)
  const matches = useMediaQuery('(min-width:600px)');

  if (!props.open) {
    return null
  }

  const height = matches ? '38px' : '12vw'

  return <Box
    sx={{
      position: 'fixed',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      color: 'white',
      alignItems: 'center',
      marginBottom: '1vh',
      justifyContent: 'center',
      pointerEvents: 'visible',
      flexDirection: 'column',
    }}
    onClick={props.onClose}>
    <Box 
      sx={{
        width: matches ? '33vw' : '80vw', boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.79)',
        padding: matches?'12px':'3vw', borderRadius: "12px", background: 'linear-gradient(180deg, #851956 0%, hsl(276, 55%, 30%) 100%)'
      }}
      onClick={e => e.stopPropagation()}>
      {props.children}

    </Box>
    <Box onClick={props.onClose}
      style={{
        marginTop: '1vh',
        width: height, height: height, fontSize: '26px', fontWeight: 'bold', color: '#eee', textAlign: 'center', lineHeight: height,
        borderRadius: height,
        cursor:'pointer',
        border: "3px solid #eee",
      }}
    >
      x
    </Box>
  </Box>
};


type DialogType = { name: string; args?: any; param?: any }

const initialState: DialogType = {
  name: "",
};

const reducer = (state: DialogType, action: any) => {
  console.log('action', action)
  switch (action.type) {
    case 'open':
      return { ...action.payload }
    case 'close':
      return initialState
    default:
      return { ...state }
  }
}

const ModalContext = createContext({ state: initialState, dispatch: (e: any) => e })

const ModalProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const CtxValue = useMemo(() => ({ state: state, dispatch: dispatch }), [])
  console.log('statestate', state.name === "search")
  return <ModalContext.Provider value={CtxValue}>
    {props.children}
    <Modal title='My Modal' open={state.name.length > 0} onClose={() => dispatch({ type: 'close' })}>
      {state.name === "search" && <SearchDialog />}
      {state.name === "guide" && <GuideDialog />}
      {state.name === "contact" && <ContactDialog />}
      {state.name === "reset" && <ResetDialog />}
      {state.name === "done" && <DoneDialog title={state.title} text={state.text} />}

    </Modal>
  </ModalContext.Provider>

}


export { Modal, ModalProvider, ModalContext };
