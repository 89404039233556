import { Box, Typography, useMediaQuery } from "@mui/material";
import { Component, PropsWithChildren, useState } from "react";

import { DataProvider } from "../components/DataProvider";
import { ModalProvider } from "../components/modal/Modal";
import { Panel } from "../components/Panel";

export default function Index(props: any) {
    const matches = useMediaQuery('(min-width:600px)');

    return (
        <Box
            className="index"
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: '0 auto',
                width: matches ? '38vw' : '100vw',
                // background: "linear-gradient(180deg, #3d030d 0%, #451b80 100%)",
                // width: "100vw",
                minHeight: "100vh",
            }}
        >
            <Typography
                style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#ddd",
                    marginTop: "6vh",
                    marginBottom: "3vh",
                }}
            >
                🍅 ToShyTo.com
            </Typography>
            <DataProvider>
                <ModalProvider>
                    <Box
                        style={{
                            padding: "6px",
                            fontSize: "18px",
                            display: "flex",
                            flexDirection: "column",
                            width: "90%",
                        }}
                    >
                        <Panel />
                    </Box>
                </ModalProvider>
            </DataProvider>
        </Box>
    );
}
