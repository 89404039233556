// import { Box, Image, Text } from "@tarojs/components"
import { CSSProperties, useContext, useState } from "react"
import { countLen, genInitMap, Map2Code } from "../../utils/Util"
import { DataContext } from "../DataProvider"
import { OptionButton } from "../OptionButton"
import Tomato from '../../assets/images/tomato.png'
import { ModalContext } from "./Modal"
import { Box, Typography, useMediaQuery } from "@mui/material"

const ResetDialog = (props:any) => {
  const matches = useMediaQuery('(min-width:600px)');
  const { state, dispatch } = useContext(DataContext)
  const { dispatch: dispatchM } = useContext(ModalContext)
  const { travel, fixed, hint } = countLen(state.TMap.flat())
  const [count, setCount] = useState({ travel: travel, fixed: fixed, hint: hint })

  const handleSubmit = () => {
    const tmpMap = genInitMap(count.travel, count.fixed, count.hint)
    dispatch({ type: 'reset', payload: { title: Map2Code(tmpMap), TMap: tmpMap } })
    dispatchM({ type: 'close' })
  }


  return <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', padding: '3vh',  }}>
    <Box style={{ fontWeight: 'bold', fontSize: '18px',marginBottom:"3vh" }}>{`current field: ${fixed}-${travel}-${hint}`}</Box>
    <Box style={{ display: 'flex',marginBottom:"3vh", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>

      <Section img name={'toshyto' + '\xa0'} count={count.fixed} onChange={(add:any) => {
        const newValue = add ? count.fixed + 1 : count.fixed - 1
        if (0 <= newValue && newValue <= 23 && newValue + count.travel <= 23 && newValue + count.travel + count.hint <= 24)
          setCount({ ...count, fixed: newValue })
      }}
      />
      <Section img name='travel' count={count.travel} onChange={(add:any) => {
        const newValue = add ? count.travel + 1 : count.travel - 1
        if (1 <= newValue && newValue <= 23 && newValue + count.fixed <= 23 && newValue + count.fixed + count.hint <= 24)
          setCount({ ...count, travel: newValue })
      }}
      />
      <Section name={'hint' + '\xa0'} count={count.hint} onChange={(add:any) => {
        const newValue = add ? count.hint + 1 : count.hint - 1
        if (1 <= newValue && newValue <= 23 && newValue + count.travel + count.fixed <= 24)
          setCount({ ...count, hint: newValue })
      }}
      />

    </Box>

    <OptionButton name='change now' onClick={handleSubmit} mt='3vh' />
  </Box>
}

const ColumnStyle: CSSProperties = { display: 'flex', flexDirection: 'column', alignItems: 'center',  }

const Section = (props:any) => {
  const matches = useMediaQuery('(min-width:600px)');
  const ImgSize=matches?'33px':'5vw'
  const Height=matches?'88px':'13vh'
  const SubHeight=matches?'33px':'8vw'
  const Width=matches?'66px':'18vw'

  const bg = 'rgba(233,233,233,0.1)'
  return <Box style={{ ...ColumnStyle,marginLeft:'1vw', justifyContent: 'space-between', alignItems: 'center',}}>
    <Box
      onClick={() => props.onChange(true)}
      style={{ ...ColumnStyle, height:Height,justifyContent: 'space-between', marginBottom: '2vh', backgroundColor: bg, 
      cursor:'pointer',
      width: Width,  padding: '2vw', borderRadius: '8px' }}
    >
      <Box style={{ marginBottom: '1vh', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', }}>
        {props.img
          ? <img alt='tomato' src={Tomato} style={{ width:ImgSize, height: ImgSize }} />
          : <Typography style={{ fontSize: '18px', fontWeight: 'bolder', color: '#fff' }}>N</Typography>
        }
        <Typography style={{ fontSize: '18px', fontWeight: 'bolder', color: '#fff',marginLeft: '2vw', }}>{props.count}</Typography>
      </Box>
      <Typography style={{ marginBottom: '1vh',fontSize: '12px', color: '#aaa' }}>{props.name}</Typography>
      <Typography style={{ marginBottom: '1vh',fontSize: '22px', fontWeight: 'bolder', color: '#fff' }} >+</Typography>
    </Box>
    <Box
      onClick={() => props.onChange(false)}
      style={{ height: SubHeight, lineHeight: SubHeight, textAlign: 'center', backgroundColor: bg, 
      cursor:'pointer',
      width: Width, padding: '2vw', borderRadius: '8px' }}
    >
      <Typography style={{ fontSize: '22px', fontWeight: 'bolder', color: '#fff' }}>-</Typography>
    </Box>
  </Box>
}

export { ResetDialog }
