import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import './modal/Modal.css';

const OptionButton=(props:any)=>{
  const matches = useMediaQuery('(min-width:600px)');

  const height=matches?'55px':'12vw'
  const width=props.width??(matches?'13vw':'50vw')
  return <Box onClick={props.onClick}

    style={{width:width,height:height,fontSize:'16px',cursor:'pointer',fontWeight:'bold',color:'#eee',textAlign:'center',lineHeight:height,
      borderRadius:'8px',marginBottom:props.mb,marginTop:props.mt,
      backgroundColor: "rgba(236, 243, 237, 0.31)",border: "3px solid #eee",}}
  >
    {props.name}
  </Box>
}

export {OptionButton}
